import React from 'react';
import time from '../../assets/images/security-time.svg';
import {Button, Flex, Image, Text} from '@chakra-ui/react';
import FormWrapper from './wrapper';

const Navigation = ({setIsDemo, sent}) => {
  return (
    <FormWrapper>
      <Flex direction={'column'} align={'center'} justify={'center'}>
        <Text color="#000" fontSize="40px" fontWeight={700}>
          Get Started
        </Text>

        <Flex mt="35px" gap="16px" align="center" justify="centers">
          <a href="https://veerge.myxellia.io/auth/onboarding">
            <Button
              w="148px"
              h="46px"
              borderRadius="8px"
              border="1px solid #4545FE"
              bg="#fff"
              _hover={{bg: '#fff'}}
            >
              <Text fontSize={'14px'} fontWeight={500} color="#4545FE">
                Create account
              </Text>
            </Button>
          </a>
          <a href="https://veerge.myxellia.io">
            <Button w="148px" h="46px" borderRadius="8px" bg="#4545FE" _hover={{bg: '#4545FE'}}>
              <Text fontSize={'14px'} fontWeight={500} color="#fff">
                Sign in
              </Text>
            </Button>
          </a>
        </Flex>
        <Flex mt="20px" gap="8px" align="center" justify="centers">
          <Image src={time} />
          <Text fontWeight={500} fontSize={'14px'} color="#3D3D3D">
            Want to schedule a demo?{' '}
            <Text onClick={() => setIsDemo(true)} cursor="pointer" as="span" color="#4545FE">
              Click here
            </Text>
          </Text>
        </Flex>
      </Flex>
    </FormWrapper>
  );
};

export default Navigation;
