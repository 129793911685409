import React from 'react';
import {Box, Image, Text} from '@chakra-ui/react';
import {VStack, Drawer, DrawerOverlay, DrawerContent, Button} from '@chakra-ui/react';
import {Link} from 'react-router-dom';
import cross from '../../assets/images/cross-black.svg';

const DrawerComp = ({drawerDisclosure}) => {
  const menuContent = [
    {text: 'Solution', link: 'https://veerge-support.myxellia.io/intro_to_veerge'},
    {text: 'Enterprise', link: 'https://veerge-support.myxellia.io/blog/new_era'},
    {text: 'Schedule Demo', link: '/schedule', isVeerge: true},
    {text: 'Free Trial', link: 'https://staging-veerge.myxellia.io'},
  ];

  return (
    <Drawer placement="top" isOpen={drawerDisclosure?.isOpen} onClose={drawerDisclosure.onClose}>
      <DrawerOverlay />
      <DrawerContent maxW="400px" px="24px" py="38px">
        <Box onClick={drawerDisclosure.onClose}>
          <Image src={cross} w="17px" h="17px" />
        </Box>
        <VStack align={'flex-start'} mt="30px" spacing={'33px'}>
          {menuContent.map(menu => (
            <Box>
              {menu.isVeerge ? (
                <Link border="none" to={menu.link}>
                  <Text
                    border="none"
                    _focus={{border: 'none', outline: 'none'}}
                    outline={'none'}
                    _focusVisible={{border: 'none', outline: 'none'}}
                    _active={{border: 'none', outline: 'none'}}
                    _activeLink={{border: 'none', outline: 'none'}}
                    fontFamily={'syne_semibold'}
                    key={menu.text}
                    onClick={drawerDisclosure.onClose}
                    color={'#011B33'}
                    fontSize={'16px'}
                    fontWeight={600}
                  >
                    {menu.text}
                  </Text>
                </Link>
              ) : (
                <a
                  href={menu.link}
                  target="_blank"
                  rel="noreferrer"
                  style={{border: 'none', outline: 'none'}}
                >
                  <Text
                    border="none"
                    _focus={{border: 'none', outline: 'none'}}
                    outline={'none'}
                    _focusVisible={{border: 'none', outline: 'none'}}
                    _active={{border: 'none', outline: 'none'}}
                    _activeLink={{border: 'none', outline: 'none'}}
                    fontFamily={'syne_semibold'}
                    key={menu.text}
                    onClick={drawerDisclosure.onClose}
                    color={'#011B33'}
                    fontSize={'16px'}
                    fontWeight={600}
                  >
                    {menu.text}
                  </Text>
                </a>
              )}
            </Box>
          ))}
        </VStack>

        <Link href={'/veerge'}>
          <Button
            outline={'none'}
            borderRadius={'0'}
            onClick={drawerDisclosure.onClose}
            w="full"
            color="white"
            px="20px"
            py="12px"
            mt="30px"
            bg={'#4545FE'}
          >
            Get Started
          </Button>
        </Link>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerComp;
